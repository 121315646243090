import React, {Component,Fragment} 			from 'react'
import axios 								from 'axios'
import {server_url, asset_url}				from '../conf'
import {StreamContext} 						from "../app/StreamContext"


const SessionListEntry = (props) =>
{
	const {session, state} 				= props
	const {history}						= props
	const {error} 						= props
	const {id}							= session

	const {onPlay}						= props


	const show_stream = (stream) => (e) =>
	{
		history.push( `/play/${stream}`)
	}


	const open_session = (session) => (e) => {

		console.log( "Opening ", session)


	}

	const onClick = (e) => {
		console.log( "Click")
		onPlay(session)
	}


	return 	<div className='session' onClick={onClick}>
				<div className='header'>
					<div className='title' id={`${session.id}`}>{session.code}</div>
					{state == 'playing' && <div className='state' onClick={show_stream(session.code)}>View Stream</div>}
					<div className='details'>
						<div className='speaker'>{session.description}</div>
					</div>
				</div>
				<div className='body'>
				</div>
			</div>
}



export class SessionList extends Component
{
	static contextType = StreamContext

	constructor(props)
	{
		super( props )
		this.state = {sessions: null}
	}



	set_sessions = ( result, err ) =>
	{
		if (!result || !result.data || !result.data.sessions)
			this.setState( {status: "error", 	error: "Presentations not loaded"} )
		else
		{
			const sessions = result.data.sessions
			this.setState( {status: "success", 	sessions: sessions} )
		}
	}

	get_sessions()
	{
		const person_key = this.props.match.params.id
		const result = axios.get( `${server_url}/video/video_sessions_json` )
					        .then( this.set_sessions )
	}

	play_session = ( session ) =>
	{
		console.log( "Play ", session.code )
		this.context.connection.emit( "play", session.code )
	}


	componentDidMount()
	{
		this.get_sessions()
	}

	render()
	{
		const {id}			= this.props.match.params
		const {sessions} 	= this.state
		if (!sessions)
			return <div></div>


		const session_list 		= []

		const play_state = this.context.play_state || {}

		for( let p of sessions )
		{
			const state = play_state[p.code] || 'stopped'
			session_list.push( <SessionListEntry 	key={`${p.id}`}
										history={this.props.history}
										session={p} state={state} onPlay={this.play_session} /> )
		}

		return <div className='session-list'>{session_list}</div>
	}
}


