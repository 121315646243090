import React, { Component } from "react"
import {StreamContext} 		from "./StreamContext"

import {MasterRouter} 		from '../app/MasterRouter'

import SocketIO				from 'socket.io-client'





export class App extends Component
{
	constructor(props)
	{
		super( props )
		this.connection = SocketIO('https://streamer.conference-registrations.net')
		this.setup_connection()
		this.state = {play_state: {}}
	}

	setup_connection()
	{
		this.connection.on( 'state', (data) =>
		{
			console.log( data.id, data.status )
			const play_state = {...this.state.play_state}
			play_state[data.id] = data.status
			console.log( "New Play_state", play_state )
			this.setState( {play_state} )
		})
	}


	render()
	{
		const cstate =  {	play_state: this.state.play_state,
							connection: this.connection
						}
		console.log( cstate.play_state )
		return 	<StreamContext.Provider value={cstate} >
					<MasterRouter/>
				</StreamContext.Provider>
	}
}
