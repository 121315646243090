import React, {Component,Fragment} 						from 'react'
import {BrowserRouter, Route, Switch,Redirect} 			from "react-router-dom"
import {AttendeeFrame}									from "../video/AttendeeFrame"

import {SessionList} 									from "../session/SessionList"

// import {SessionVideos} 									from "../session/SessionVideos"

import './Master.scss'

export class MasterRouter extends Component
{
	constructor(props)
	{
		super(props)
	}



	render = () =>
	{
		return 	<BrowserRouter>
					<Switch>
						<Route exact path="/"				component={SessionList} />
						<Route exact path="/play/:stream"	component={AttendeeFrame} />
					</Switch>
				</BrowserRouter>
	}
}




