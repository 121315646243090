import React, { Component } from 'react';
import VideoPlayer from './VideoPlayer.js';

export class VideoApp extends Component {
    player = {}


    onPlayerReady = (player) =>
    {
        console.log("Player is ready: ", player);
        this.player = player;
    }

    onVideoPlay = (duration) =>
    {
        console.log("Video played at: ",    duration);
    }

    onVideoPause = (duration) =>
    {
        console.log("Video paused at: ",    duration);
    }

    onVideoTimeUpdate = (duration) =>
    {
        //console.log("Time updated: ",       duration);
    }

    onVideoSeeking = (duration) =>
    {
        console.log("Video seeking: ",      duration);
    }

    onVideoSeeked = (from, to) =>
    {
        console.log(`Video seeked from ${from} to ${to}`);
    }

    onVideoEnd = () =>
    {
        console.log("Video ended");
    }

    render() {

console.log( "SRC: ", this.props.src )

        return (
            <div>
                <VideoPlayer
                    controls={true}
                    src={this.props.src}

                    width="720"
                    height="420"
                    autoplay={true}
                    onReady={this.onPlayerReady}
                    onPlay={this.onVideoPlay}
                    onPause={this.onVideoPause}
                    onTimeUpdate={this.onVideoTimeUpdate}
                    onSeeking={this.onVideoSeeking}
                    onSeeked={this.onVideoSeeked}
                    onEnd={this.onVideoEnd}
                />
            </div>
        );
    }
}
export default VideoApp;