import React, {Component,Fragment} 						from 'react'
import {VideoApp} 										from './VideoApp'
import {StreamContext}									from '../app/StreamContext'

export class AttendeeFrame extends Component
{
	sources1 = [{	id: 		"stream1",
						url2: 		"https://hls.conradie.net/32000.m3u8",
						urlbase: 		"https://hls.conference-registrations.net/hls/",
					 	mimeType: 	"application/x-mpegURL"}]
	video1   = {id: "video1", sources: this.sources1}


	sources2 = [{	id: 		"stream2",
						url: 		"https://hls.conference-registrations.net/hls/stream2.m3u8",
						url2: 		"https://red5.conference-registrations.net/live/moderate1.m3u8",
			 			mimeType: 	"application/x-mpegURL"}]
	video2   = {id: "video2", sources: this.sources2}


	constructor(props)
	{
		super(props)
		this.state = {videosource: this.video1}
	}

	channelswitch = (e) => 	{
								if (this.state.videosource == this.video1)
									this.setState( {videosource : this.video2})
								else
									this.setState( {videosource : this.video1})
							}

	render()
	{
		const stream = this.props.match.params.stream
		return 	<div>
					<VideoApp src={`${this.state.videosource.sources[0].urlbase}/${stream}.m3u8`}/>
				</div>

	}
}